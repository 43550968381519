@tailwind base;
@tailwind components;
@tailwind utilities;

input[type="file"] {
    display: none;
}

input[type="radio"]:checked {
    background-image: none;
}

@layer components {
    .overlay {
        @apply fixed w-screen h-screen z-10 bg-gray-700 opacity-70
    }
}

.react-date-picker {
    padding: 0 10px!important;
    display: flex!important;
}
.react-date-picker__wrapper {
    border: none!important;
    align-items: center!important;
}
.react-date-picker__button svg {
    stroke: #0B486B!important;
}
.react-date-picker__calendar--open {
    width: 280px!important;
    left: -50px!important;
}
.react-date-picker__inputGroup {
    font-size: 16px!important;
}
.fill-pen path {
    fill: #b1b1b1!important;
}
.react-calendar__tile--active {
    background-color: #0B486B!important;
    color: white;
}
.react-calendar {
    border: 1px solid #0B486B!important;
    border-radius: 5px!important;
}
.react-calendar button:hover {
    background-color: #A6CC44!important;
}
.react-date-picker__inputGroup input {
    background-color: transparent!important;
}
.hiddenBtn .react-date-picker__button {
    visibility: hidden;
}
.arrowUp-disabled > path, .arrowDown-disabled > path {
    fill: #d8dadc;
}
svg.arrowUp-disabled:hover {
    transform: scale(1) rotate(180deg)!important;
}
svg.arrowDown-disabled:hover {
    transform: scale(1)!important;
}
.arrowUp-disabled, .arrowDown-disabled {
    cursor: auto!important;
}

.listWidth {
    min-width: 320px;
}
.maxProduct {
    width: 600px!important;
}
.bonusLabel  {
    border-bottom-right-radius: 0;
}
.bonusLabel::before {
    content: '';
    position: absolute;
    top: 20px;
    right: 0;
    width: 6px;
    height: 12px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    z-index: 2;
}
.bonusLabel::after {
    content: '';
    position: absolute;
    top: 20px;
    right: 0;
    width: 6px;
    height: 6px;
    z-index: 1;
}
.bonusLabelSecondary::after {
    background-color: #73BC4B;
}
.bonusLabelSecondary::before {
    background-color: #446e2e;
}
.bonusLabelInfo::after {
    background-color: #7EA9BF;
}
.bonusLabelInfo::before {
    background-color: #47606d;
}
.percentLabel {
    position: absolute;
    top: -15px;
    left: -15px;
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    pointer-events: none;
}
.percentLabel::before {
    position: absolute;
    width: 150%;
    height: 40px;
    transform: rotate(-45deg) translateY(-20px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    color: white;
    letter-spacing: 0.1em;
    box-shadow: 0 5px 10px rgba(0,0,0,0.1);
}
.percentLabel50::before {
    content: '50%';
    background-color: #73BC4B;
}
.percentLabel65::before {
    content: '65%';
    background-color: #7EA9BF;
}
.percentLabel::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 13px;
    height: 13px;
    z-index: -1;
}
.percentLabel50::after {
    background-color: #446e2e;
    box-shadow: 107px -107px #446e2e; 
}
.percentLabel65::after {
    background-color: #47606d;
    box-shadow: 107px -107px #47606d; 
}
.priceBox::before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 110px;
    height: 12px;
    background: linear-gradient(-135deg, #fff 6px, transparent 0%),
    linear-gradient(135deg, #fff 6px, transparent 0%);
    background-size: 11px;
    transform: rotate(90deg) translateY(-50px);
}